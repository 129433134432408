import  React from 'react';
import { FaSearch } from 'react-icons/fa';
import './homecards.css';



function HomeCardSelect() {

  return (
    <>
      <li className='home__cards__cta__item'>
          <figure className='home__cards__cta__item__pic-wrap'>
            <FaSearch className="ctaIcon" color='black'/>
          </figure>
          <div className='home__cards__cta__item__info'>
            <h2 className='home__cards__cta__item__text'>1. Explore</h2>
            <p className='home__cards__cta__subheader__text'>our selection of Sweepstakes</p>
          </div>
      </li>
    </>
  );
}

export default HomeCardSelect;