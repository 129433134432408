import  React from 'react';
import { FaPeopleArrows } from 'react-icons/fa';
import './homecards.css';


function HomeCardShare() {

  return (
    <>
    <li className='home__cards__cta__item'>
        <figure className='home__cards__cta__item__pic-wrap'>
          <FaPeopleArrows  className="ctaIcon" color='black'/>
        </figure>
        <div className='home__cards__cta__item__info'>
          <h2 className='home__cards__cta__item__text'>3. Share</h2>
          <p className='home__cards__cta__subheader__text'>the results instantly</p>
        </div>
    </li>
  </>
  );
}

export default HomeCardShare;