import React from 'react';
import './homecards.css';
import { CustomButton } from '../Button/CustomButton';
import { useNavigate } from 'react-router-dom';
import { getNextPathname } from '../../common/constants';
import HomeCardSelect from './HomeCardSelect';
import HomeCardWatch from './HomeCardWatch';
import HomeCardShare from './HomeCardShare';
import HomeCardItem from './HomeCardItem';
import { PATH_NAMES } from '../../app.constants';
import { womensT20WorldCup2024Constants } from '../../constants/cricket/womensT20WorldCup2024Constants';
import { oleksandrUsykTysonFuryConstants } from '../../constants/boxing/oleksandrUsykTysonFuryConstants';


function HomeCards() {

  let navigate = useNavigate();

  const routeChange = () =>{
    const nextPath = getNextPathname("BROWSE_SWEEPSTAKES");
    sessionStorage.setItem('nextPath', nextPath);
    navigate(nextPath);
  }

  return (
    <div className='home--cards'>
      <div className="home--cards--row-one">
        <div className='row-s1'>
          <h1 className='callToAction'>Fancy a Sweepstake?</h1>
          <p>Take part in FREE Sweepstakes with family and friends</p>
          <CustomButton buttonSize='btn--medium' buttonColor='clear' buttonStyle='btn--border' onClick={routeChange} testId={"browse-button"}>Explore all Sweepstakes</CustomButton>
        </div>
      </div>
      <div className='home--cards--row-two'>
        <div className='home__cards__container'>
          <div className='home__cards__wrapper'>
            <ul className='home__cards__items_cta'>
              <HomeCardSelect/>
              <HomeCardWatch/>
              <HomeCardShare/>
            </ul>
          </div>
        </div>
        <div className='home__cards__container'>
          <div className='home__cards__wrapper'>
            <h2 className='home__cards__heading'>Our Upcoming Sweepstakes</h2>
            <ul className='home__cards__items'>
              <HomeCardItem {...womensT20WorldCup2024Constants} sweepstake={PATH_NAMES.WOMENS_T20_WORLD_CUP_2024}/>
              <HomeCardItem {...oleksandrUsykTysonFuryConstants} sweepstake={PATH_NAMES.USYK_FURY_BOXING}/>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeCards;