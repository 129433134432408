import BoxingRing from '../../assets/boxing-ring.jpg'
import { USER_EVENTS } from '../../stateMachine/StateMachine'

export const oleksandrUsykTysonFuryConstants = {
    lightText: true,
    topLine: 'Oleksandr Usyk vs Tyson Fury Sweepstake',
    headline: 'Oleksandr Usyk vs Tyson Fury',
    subText: 'Who will claim the winning round?',
    metaDescription: "Get ready for the Oleksandr Usyk vs. Tyson Fury fight on December 21st with a free sweepstake from The Sweepstaker! Join friends and family in predicting which round the fight will be won. Start your Oleksandr Usyk vs. Tyson Fury sweepstake now and enjoy the excitement!",
    metaKeywords: "Oleksandr Usyk vs. Tyson Fury, Boxing, Fury vs Usyk, sweepstake, free, The Sweepstaker",
    sweepstakeSelectedEvent: USER_EVENTS.USYK_FURY_SELECTED,
    startDate: '2024/12/21',
    endDate: '2024/12/21',
    img: BoxingRing,
    category: "boxing",
    alt: 'Boxing Ring',
    items: [
        "Usyk win - Round 1",
        "Usyk win - Round 2",
        "Usyk win - Round 3",
        "Usyk win - Round 4",
        "Usyk win - Round 5",
        "Usyk win - Round 6",
        "Usyk win - Round 7",
        "Usyk win - Round 8",
        "Usyk win - Round 9",
        "Usyk win - Round 10",
        "Usyk win - Round 11",
        "Usyk win - Round 12",
        "Usyk win - Decision or Technical Decision",
        "Fury win - Round 1",
        "Fury win - Round 2",
        "Fury win - Round 3",
        "Fury win - Round 4",
        "Fury win - Round 5",
        "Fury win - Round 6",
        "Fury win - Round 7",
        "Fury win - Round 8",
        "Fury win - Round 9",
        "Fury win - Round 10",
        "Fury win - Round 11",
        "Fury win - Round 12",
        "Fury win - Decision or Technical Decision",
        "Draw"
    ]
}