import  React from 'react';
import { FaLaptop } from 'react-icons/fa';
import './homecards.css';


function HomeCardWatch() {

  return (
      <>
      <li className='home__cards__cta__item'>
          <figure className='home__cards__cta__item__pic-wrap'>
            <FaLaptop className="ctaIcon" color='black'/>
          </figure>
          <div className='home__cards__cta__item__info'>
            <h2 className='home__cards__cta__item__text'>2. Watch</h2>
            <p className='home__cards__cta__subheader__text'>the Live Draw or skip to results</p>
          </div>
      </li>
    </>
  );
}

export default HomeCardWatch;